export const srcParam = "src";
export const storageField = "source-app";

export const AppKey = {
  OLD: "v1",
  PARTNER: "v2",
  NEW: "v3",
}

export const appUrls = {
  [AppKey.OLD]: "/",
  [AppKey.PARTNER]: process.env.REACT_APP_PARTNER_OB_URL,
  [AppKey.NEW]: process.env.REACT_APP_NEW_ONLINE_BOOKING_URL,
};

export const isFromOutside = () => {
  const srcApp = sessionStorage.getItem(storageField);

  if (srcApp === AppKey.NEW || srcApp === AppKey.PARTNER) {
    return srcApp
  }
}

export const goToHomePage = (src) => {
  sessionStorage.removeItem(storageField);
  return document.location = appUrls[src];
}