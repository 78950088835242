import React, { memo, useContext, useMemo } from "react";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";

import { AccountContext } from "../../Contexts/account";
import { LocationContext } from "../../Contexts/location";

export const SplitProvider = memo(({ children }) => {
  const [account] = useContext(AccountContext);
  const [location] = useContext(LocationContext);

  const splitAttributes = useMemo(() => {
    const attributes = {};

    if (account?.data?.id) {
      attributes.user_id = account.data.id;
    }

    if (location?.data?.id) {
      attributes.location_id = location.data.id;
      attributes.location_name = location.data.name;
      attributes.location_timezone_name = location?.time_zone?.name;
    }

    return attributes;
  }, [account, location]);



  const config = useMemo(
    () => ({
      core: {
        authorizationKey: process.env.REACT_APP_SPLIT_AUTH_KEY,
        key: process.env.REACT_APP_SPLIT_KEY,
      },
    }),
    [],
  );

  return (
    <SplitFactoryProvider config={config} updateOnSdkTimedout={true} attributes={splitAttributes}>
      {({ factory, isReady, isTimedout, lastUpdate }) => {
        if (!isReady) return <></>;

        return children;
      }}
    </SplitFactoryProvider>
  );
})